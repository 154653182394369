import { useDispatch } from 'react-redux';
import { Icon } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash }  from '@fortawesome/free-solid-svg-icons';
import { removeBed, updateSquare } from '../../store/beds';
import Square from './square';
import styles from './bed.module.css';

function Bed({
  bed
}) {
  const dispatch = useDispatch();

  const deleteBed = () => dispatch(removeBed(bed));
  const updateBedSquare = square => dispatch(updateSquare({bed, square}));

  return (
    <div>
      <div className={styles.header}>
        <Icon align='right' onClick={deleteBed}>
          <FontAwesomeIcon icon={faTrash} />
        </Icon>
      </div>
      <div className={styles.container}>
        {bed.squares.map(square => (
          <Square 
            key={`square-${square.row}-${square.column}`} 
            style={{ flexBasis: `calc(${100 / bed.length}% - 2px)` }}
            plotSquare={square}
            updateSquare={updateBedSquare}
          />
        ))}
      </div>
    </div>
  )
}

export default Bed;