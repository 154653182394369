import classnames from "classnames";
import Svg from "components/svg";
import { useSelector } from "react-redux";
import classes from "./square.module.css";
import styles from "./square.module.css";

function Square({ plotSquare, updateSquare, ...rest }) {
  const activeVegetable = useSelector((state) => state.activeVegetable);

  function assignVegetable() {
    if (!activeVegetable) {
      return;
    }

    updateSquare({ ...plotSquare, assignedVegetable: activeVegetable });
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles.selectable]: activeVegetable != null,
      })}
      {...rest}
    >
      <div className={styles.content} onClick={assignVegetable}>
        {plotSquare.assignedVegetable && (
          <div className={classes.svgDiv}>
            <Svg name={plotSquare.assignedVegetable.name} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Square;
