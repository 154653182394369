import { ReactComponent as Carrot } from "assets/carrot.svg";
import { ReactComponent as Tomato } from "assets/tomato.svg";
import { ReactComponent as Broccoli } from "assets/broccoli.svg";

function Svg({ name, ...rest }) {
  switch (name) {
    case "Carrots":
      return <Carrot {...rest} />;
    case "Tomatos":
      return <Tomato {...rest} />;
    case "Broccoli":
      return <Broccoli {...rest} />;
    default:
      return null;
  }
}

export default Svg;
