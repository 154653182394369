import { useEffect, useState } from "react";
import { Panel } from "react-bulma-components";
import { useDispatch } from "react-redux";
import { setActiveVegetable } from "store/activeVegetable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import supabase from "network";
import Svg from "components/svg";

function VegetableSelector() {
  const [allVegetables, setAllVegetables] = useState([]);
  const dispatch = useDispatch();

  const selectVegetable = (veg) => dispatch(setActiveVegetable(veg));

  useEffect(() => {
    async function fetch() {
      const { data } = await supabase.from("vegetables").select();
      setAllVegetables(data);
    }
    fetch();
  }, []);

  return (
    <Panel>
      <Panel.Header>Vegetables</Panel.Header>
      <Panel.Block renderAs="a" onClick={() => selectVegetable(null)}>
        <Panel.Icon>
          <FontAwesomeIcon icon={faBan} />
        </Panel.Icon>
        Clear
      </Panel.Block>
      {allVegetables.map((vegetable) => (
        <Panel.Block
          key={vegetable.id}
          renderAs="a"
          onClick={() => selectVegetable(vegetable)}
        >
          <Panel.Icon>
            <Svg name={vegetable.name} />
          </Panel.Icon>
          {vegetable.name}
        </Panel.Block>
      ))}
    </Panel>
  );
}

export default VegetableSelector;
