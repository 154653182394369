import { useSelector } from "react-redux";
import useMousePosition from "hooks/useMousePosition";
import Svg from "components/svg";
import classes from "./vegetableCursor.module.css";

function VegetableCursor() {
  const activeVegetable = useSelector((state) => state.activeVegetable);
  const beds = useSelector((state) => state.beds);
  const { clientX, clientY } = useMousePosition();

  if (!activeVegetable || beds.length === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Svg
        name={activeVegetable.name}
        className={classes.icon}
        style={{ left: clientX - 10, top: clientY - 10 }}
      />
    </div>
  );
}

export default VegetableCursor;
