import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = [];

// example of bed object
// eslint-disable-next-line no-unused-vars
const bed = {
  name: '',
  width: 1,
  length: 1,
  squares: [{}]
}

const bedsSlice = createSlice({
  name: 'beds',
  initialState,
  reducers: {
    addNewBed: (state, action) => {
      const { width, length } = action.payload;
      let squares = [];
      Array(width).fill().forEach((_, row) => Array(length).fill().forEach((_, column) => {
        squares.push({ id: uuid(), row, column });
      }));

      state.push({
        id: uuid(),
        name: `Bed ${state.length + 1}`,
        width: action.payload.width,
        length: action.payload.length,
        squares: squares
      })
    },
    removeBed: (state, action) => {
      return state.filter(bed => bed.name !== action.payload.name);
    },
    updateSquare: (state, action) => {
      state.forEach(bed => {
        if (bed.id === action.payload.bed.id) {
          bed.squares = bed.squares.map(square => {
            return square.id === action.payload.square.id
              ? action.payload.square
              : square;
          })
        }
      })
    }
  }
});

export const { addNewBed, removeBed, updateSquare } = bedsSlice.actions;
export default bedsSlice.reducer;
