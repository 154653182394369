import styles from './App.module.css';
import 'bulma/css/bulma.min.css';
import { Container } from 'react-bulma-components';
import BedDesign from 'pages/bedDesign';

function App() {
  return (
    <div className={styles.app}>
      <Container className={styles.mainContent}>
        <BedDesign />
      </Container>
    </div>
  );
}

export default App;
