import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Box,
  Heading
} from 'react-bulma-components';
import classnames from 'classnames';
import InputControl from '../inputControl';
import { addNewBed } from '../../store/beds';
import styles from './module.css';

function CreateBed({ rest }) {
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const dispatch = useDispatch();

  function validateNumber(value, setFunc) {
    if (!value) {
      setFunc('');
      return;
    }
    if (isNaN(value.slice(-1))) {
      return;
    }
    setFunc(parseInt(value));
  }

  function createBed() {
    dispatch(addNewBed({ width, length }));
  }

  return (
    <Box {...rest}>
      <Heading>Create Garden Bed</Heading>
      <InputControl 
        label='Length (ft)'
        value={length} 
        onChange={e => validateNumber(e.target.value, setLength)}
      />
      <InputControl 
        label='Width (ft)'
        value={width}
        onChange={e => validateNumber(e.target.value, setWidth)}
      />
      <Button 
        className={classnames(styles.button, 'is-primary')} 
        type='primary'
        onClick={createBed}
      >
        Create
      </Button>
    </Box>

  )
}

export default CreateBed;