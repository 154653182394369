import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'react-bulma-components';
import Bed from '../bed';

function BedsEditor() {
  const beds = useSelector(state => state.beds);
  const [currentBedId, setCurrentBedId] = useState(null);

  useEffect(() => {
    if (!currentBedId && beds.length > 0) {
      setCurrentBedId(beds[0].id);
    } else if (beds.length === 0 && currentBedId) {
      setCurrentBedId(null);
    }
  }, [currentBedId, beds]);

  if (beds.length === 0) {
    return null;
  }

  const currentBed = beds.find(bed => bed.id === currentBedId);

  return (
    <div>
      <Tabs align='centered'>
        {beds.map(bed => (
          <Tabs.Tab 
            key={bed.name} 
            active={bed.id === currentBedId}
            onClick={() => setCurrentBedId(bed.id)}
          >
            {bed.name}
          </Tabs.Tab>
        ))}
      </Tabs>
      {currentBed && <Bed bed={currentBed} /> }
    </div>
  );
}

export default BedsEditor;