import { useSelector } from 'react-redux';
import { Container } from 'react-bulma-components';
import BedsEditor from '../../components/bedsEditor';
import CreateBed from '../../components/createBed';
import VegetableSelector from '../../components/vegetableSelector';
import VegetableCursor from 'components/vegetableCursor';
import styles from './bedDesign.module.css';

function BedDesign() {
  const beds = useSelector(state => state.beds);

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.topContainer}>
          <CreateBed />
          {beds.length > 0 && <VegetableSelector />}
        </div>
      </Container>
      <div className={styles.bedEditor}>
        <BedsEditor />
      </div>
      <VegetableCursor />
    </div>
  )
}

export default BedDesign;