import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const activeVegetableSlice = createSlice({
  name: 'activeVegetable',
  initialState,
  reducers: {
    setActiveVegetable: (state, action) => {
      return action.payload
    },
  }
});

export const { setActiveVegetable } = activeVegetableSlice.actions;
export default activeVegetableSlice.reducer;
