import { Columns } from 'react-bulma-components';
import {
  Field,
  Label,
  Control,
  Input
} from 'react-bulma-components/lib/components/form';
import styles from './module.css';

function InputControl({ 
  label, 
  placeholder,
  value,
  onChange, 
  ...rest 
}) {
  return (
    <Field>
      <Columns className={styles.columns}>
        <Columns.Column size={4}>
          <Label>{label}</Label>
        </Columns.Column>
        <Columns.Column>
          <Control>
            <Input 
              placeholder={placeholder} 
              value={value} 
              onChange={onChange}
              {...rest}
            />
          </Control>
        </Columns.Column>
      </Columns>
    </Field>
  )
}

export default InputControl;